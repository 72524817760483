<template>
  <common-form
      ref="form"
      url="web/device-company"
      :items="items"
      :edit="edit"
      @success="$emit('success')">
  </common-form>
</template>
<script>

export default {
  data() {
    return {
      edit: false,
      treeData: []
    }
  },
  computed: {
    items() {
      return [
        {
          type: "tree-select",
          name: "上级分公司",
          key: "parentId",
          treeData: this.treeData
        },
        {
          type: "input",
          name: "名称",
          key: "name",
          required: true,
        },
      ]
    }
  },
  methods: {
    show(model = {}) {
      this.edit = model.id > 0
      this.$refs.form.show(model)

      this.$get('web/device-company').then((r) => {
        this.treeData = r.data
      })
    },

  }
}
</script>
